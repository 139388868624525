import React , {useContext, useEffect} from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import styles from './style/Claim.module.scss'
import Edit from '../img/preInspection/edit.png'
import Controlbar from './Controlbar'
import logoFooter from '../img/preInspection/footer.png'
import nextBtn from '../img/preInspection/blueNext.png'
import loadingImg from '../img/loading.gif'
import { AppContext } from './context'
import { checkCookies, removeCookies, makeAuthRequest } from './context/utils'
import Cookies from 'js-cookie'
import lang from './language/Claim.json'
import config from '../config/config.json'

let remarkInspection = ""
// let shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let shortMonths = ['Dec', 'Nov', 'Oct', 'Sep', 'Aug', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Feb', 'Jan']
const Claim = () =>{
  const {urlLink, octoClients} = config
  const { language, preInspectionList, setPreInspectionList, currentPreInspectionPage, setCurrentPreInspectionPage, totalPages,
          setTotalPages, loading, setLoading, showRemarkModal, setShowRemarkModal, remark, setRemark, setDate, setSearch, setAgentSearch,
          setLanguage, setLanguageList, setModule, searchInspection, reportMonth, setReportMonth, reportDownloading, setReportDownloading} = useContext(AppContext)

  const navigate = useNavigate()

  // function recieves inspection data list from backend
  const getInspectionData = (page) => {

    return new Promise((resolve, reject) => {

      // limit: number of inspection data on a single page, page_no: page number displayed on screen
      makeAuthRequest(`${urlLink}inspection-record`, {client_id: Cookies.get('client'), limit: 20, page_no: page, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId")})
      .then((res)=> res.json()
      .then((data)=>{

      //setting preinspection list as a state to be shown on screen
      setPreInspectionList(data)
      resolve({status: true})
      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection data")
      })
    })
  }

  //funtion returns and sets total pages to be displayed on screen
  const getTotalClientInspectionCount = async (page) => {

    return new Promise((resolve, reject)=>{

      makeAuthRequest(`${urlLink}count-inspection`, {client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId")})
      .then((res)=> res.json()
      .then((data)=>{

        //limiting page count to 10
        let limit = data.count
        if (limit === 0){
          return resolve({status: true})
        } 

        if(data.count > 200){
          limit = 200
        }
        let pageLimit = Math.ceil(limit / 20)
        let temp = []
        for(let i = 1; i <= pageLimit; i++){
          temp.push(i)
        }
        setTotalPages(temp)
        setCurrentPreInspectionPage(page)
        resolve({status: true})

      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection count")
      })
    })
  }
  
  //function controls pagination arrows
  const onChangePage = (direction, value) => {
    let temp = 0
    if(direction === 'left'){
      if(currentPreInspectionPage > value){
        setLoading(true)
        temp = currentPreInspectionPage - value
        setCurrentPreInspectionPage(temp)
        Promise.all([getInspectionData(temp), getTotalClientInspectionCount(temp)]).then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }else{
      if(currentPreInspectionPage <= totalPages[totalPages.length - 1] - value){
        setLoading(true)
        temp = currentPreInspectionPage + value
        setCurrentPreInspectionPage(temp)
        Promise.all([getInspectionData(temp), getTotalClientInspectionCount(temp)]).then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }
  }

  // function sets remark and save to db
  const sendRemarkData = async () => {
    
    const res = await makeAuthRequest(`${urlLink}remark/update`, {inspection_id: remarkInspection, remark: remark, client_id: Cookies.get('client'), page_no: currentPreInspectionPage, client_type: Cookies.get('clientType')})

    remarkInspection=""
    setRemark("")

    if(res.ok){
      setShowRemarkModal(false)
      getInspectionData(currentPreInspectionPage)
    }

  }

  //function download inspection report
  const downaloadReport = async() => {
    if(reportMonth === 0 || reportDownloading) return
    setReportDownloading(true)
    setTimeout(() => {
      setReportDownloading(false)
    }, 8000);
    const res = await makeAuthRequest(`${urlLink}download/csv`, {client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), month: reportMonth, year: new Date().getMonth() < reportMonth - 1 ? new Date().getFullYear() - 1 : new Date().getFullYear()})
    if(res.ok){
      const data = await res.text()
      setReportDownloading(false)
      const url = window.URL.createObjectURL(new Blob([data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "report.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const localTime = (time) => {
    let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let dateTime = time.split(", ")
    let mon = Months.indexOf(dateTime[0].split(" ")[1])
    let local = new Date(Date.UTC(dateTime[0].split(" ")[2], mon, dateTime[0].split(" ")[0], dateTime[1].split(":")[0], dateTime[1].split(":")[1]))
    let Dte = local.getDate()
    let Month = Months[local.getMonth()]
    let year = local.getFullYear()
    let hour = local.getHours() % 12 ? local.getHours() % 12 >= 10 ? local.getHours() % 12 : `0${local.getHours() % 12}` : 12 
    let minute = local.getMinutes() >= 10 ? local.getMinutes() : `0${local.getMinutes()}` 
    let ampm = local.getHours() >= 12 ? 'PM' : 'AM'

    return `${Dte} ${Month} ${year}, ${hour}:${minute} ${ampm}`
  }

  useEffect(()=>{
    setLoading(true)
    setReportDownloading(false)
    setReportMonth(new Date().getMonth() + 1)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else {
      setLanguage(Cookies.get('current-language'))
      setModule(JSON.parse(Cookies.get('modules')))
      //update language list here according to selected language*****(remove comment after implementing language json)
      
      setLanguageList(JSON.parse(Cookies.get('all-languages')))
      if(!searchInspection){
        Promise.all([getInspectionData(currentPreInspectionPage), getTotalClientInspectionCount(currentPreInspectionPage)]).
        then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }
  },[navigate, setLoading, setSearch, setAgentSearch, setDate, setModule, setLanguage, setLanguageList])

  return (
    <div className={styles.mainContainer}>
    <Navbar />
    <Controlbar/>
    {loading ? 
      <div className={styles.loading} >
        <img src={loadingImg} alt='loading...'/>
      </div>: 
      <div className={styles.mainSubContainer}>
        <div className={styles.InspectionHeader}>
          <div className={styles.InspectionTitle}>
            <h2>{lang["Claim"][language]}</h2>
          </div>
          <div className={styles.pagination}>
            <i className="fa-solid fa-angles-left" style={{backgroundColor: currentPreInspectionPage <= 2 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 2)}></i>
            <i className="fa-solid fa-angle-left" style={{backgroundColor: currentPreInspectionPage === 1 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 1)}></i>

            {totalPages.map((item, index)=>{
              return <h4 key={index} style={{backgroundColor: currentPreInspectionPage === item ? '#157AFF' : null, 
              color: currentPreInspectionPage === item ? 'white' : null}} onClick={()=>{
                setLoading(true)
                setCurrentPreInspectionPage(item)
                Promise.all([getInspectionData(item), getTotalClientInspectionCount(item)]).then((data)=>{
                  setLoading(false)
                  setSearch('')
                  setAgentSearch('')
                  setDate('')
                })
              }}>{item}</h4>
            })}

            <i className="fa-solid fa-angle-right" style={{backgroundColor: currentPreInspectionPage + 1 > totalPages[totalPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 1)}></i>
            <i className="fa-solid fa-angles-right" style={{backgroundColor: currentPreInspectionPage + 2 > totalPages[totalPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 2)}></i>
          </div>
          <div className={styles.reportDownload} style={octoClients.includes(Cookies.get("master")) ? {visibility: "hidden"}: {visibility: "visible"}}>
              <select className={styles.monthList} value={reportMonth} onChange={(e)=>setReportMonth(e.target.value)}>
              <option value={0}>{lang["Select Month"][language]}</option>
                
                {shortMonths.map((item, index) => {
                    if (11 - index <= new Date().getMonth()){
                      return <option key={index} value={12 - index}>{item}-{new Date().getMonth() < 11 - index ? (new Date().getFullYear() - 1) % 100 : new Date().getFullYear() % 100} </option>
                    }
                })}
                {shortMonths.map((item, index) => {
                    if (11 - index > new Date().getMonth()){
                      return <option key={index} value={12 - index}>{item}-{new Date().getMonth() < 11 - index ? (new Date().getFullYear() - 1) % 100 : new Date().getFullYear() % 100} </option>
                    }
                })}
              </select>
              <button className={styles.ReportDownloadBtn} style={reportDownloading ? {background: "#D9DEE9"} : {background: "#157AFF"}} onClick={()=>downaloadReport()}>{reportDownloading ? <img className={styles.reportDownloadLoading} src={loadingImg}></img> : lang["Download Report"][language]}</button>
          </div>
        </div>
        
        <div className={styles.InspectionTable}>
          <table className={styles.Table}>
            <thead>
              <tr>
                <th className={styles.InspectionTableHead}>{lang["Date"][language]}</th>
                <th className={styles.InspectionTableHead}>{lang["Inspection"][language]}</th>
                <th className={styles.InspectionTableHead}>{lang["Case"][language]}</th>
                <th className={styles.InspectionTableHead}>{lang["Type"][language]}</th>
                <th className={styles.InspectionTableHead}>Persona ID</th>
                <th className={styles.InspectionTableHead}>{lang["Agent Name"][language]}</th>
                {!(octoClients.includes(Cookies.get("master"))) && <th className={styles.InspectionTableHead}>{lang["Approved"][language]}</th>}
                <th className={styles.InspectionTableHead}>{lang["License Plate"][language]}</th>
                <th className={styles.InspectionTableHead}>{lang["Result Status"][language]}</th>
                {Cookies.get("master") === "client_1343" && <th className={styles.InspectionTableHead}>VIN</th>}
                {(!(octoClients.includes(Cookies.get("master"))) && Cookies.get("master") !== "client_1343") && <th className={styles.InspectionTableHead}>{Cookies.get('master') === "generali_demo" || Cookies.get('master') === "generali_internal" ? lang["policyNumber"][language] : lang["Report Number"][language]}</th>}
                {(!(octoClients.includes(Cookies.get("master"))) && Cookies.get("master") !== "client_1343") && <th className={styles.InspectionTableHead}>{lang["unique"][language]}</th>}
                <th className={styles.InspectionTableHead}>{Cookies.get("master") === "gipsa_inspekt" ? "TAT(min)" : lang["Remark"][language]}</th>
              </tr>
            </thead>
            <tbody>
            {preInspectionList.map((row,i) => <tr key={i} onClick={ () => {} }>
                <td>{localTime(row.inspection_date)}</td>
                <td style={{cursor: "pointer", color: "#157aff"}} onClick={()=>{
                  if(row.status === "Available") return window.open(`${window.location.origin}/claim/${row.inspection_id}`, "_blank")
                  if(row.status === "Rejected") return window.open(`${window.location.origin}/rejected/${row.inspection_id}`, "_blank")
                }}>{row.inspection_id}</td>
                {row.client_inspection_id ? row.client_inspection_id.length > 18 ? <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(row.client_inspection_id) }} style={{cursor: "pointer"}}>{row.client_inspection_id.slice(0,18)}...</td>: <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(row.client_inspection_id)}} style={{cursor: "pointer"}}>{row.client_inspection_id}</td> : <td></td>}
                {row.web_app_case === "1" ? <td>{lang["True"][language]}</td>: <td>{lang["False"][language]}</td>}
                <td>{row.persona_id ? row.persona_id : "NA"}</td>
                {row.agent_name !== "NA" ? <td>{row.agent_name}</td> : row.tl_name !== "NA" ? <td>{row.tl_name}</td> : <td>{lang["Master"][language]}</td> }
                {!(octoClients.includes(Cookies.get("master"))) && <td><div className={styles.icon}><div>{Object.keys(lang).includes(row.agent_approved) ? lang[row.agent_approved][language] : row.agent_approved}</div>{row.issue_policy && <div className={styles.infoContainer} title={Object.keys(lang).includes(row.issue_policy) ? lang[row.issue_policy][language]: row.issue_policy}><i className="fa-solid fa-circle-info"></i></div>}</div></td>}
                <td>{row.license_plate}</td>
                <td><div className={styles.icon}><div>{Object.keys(lang).includes(row.status) ? lang[row.status][language]: row.status}</div>{row.message && <div className={styles.infoContainer} title={Object.keys(lang).includes(row.message) ? lang[row.message][language]: row.message}><i className="fa-solid fa-circle-info"></i></div>}</div></td>
                {Cookies.get("master") === "client_1343" && <td>{row.vin ? row.vin : "---"}</td>}
                {(!(octoClients.includes(Cookies.get("master"))) && Cookies.get("master") !== "client_1343") && <td>{row.policy_number}</td>}
                {(!(octoClients.includes(Cookies.get("master"))) && Cookies.get("master") !== "client_1343") && <td>{!row.web_app_form_unique_desc ? "" : `${row.web_app_form_unique_no}: ${row.web_app_form_unique_desc}`}</td>}
                <td><div className={styles.Remark}><p>{row.remark}</p> <img src={Edit} alt='edit' onClick={()=> {
                  setShowRemarkModal(true)
                  remarkInspection = row.inspection_id
                  if(row.remark) setRemark(row.remark)
                }}/><img className={styles.InspectionNext} onClick={()=>{
                  if(row.status === "Available") return window.open(`${window.location.origin}/claim/${row.inspection_id}`, "_blank")
                  if(row.status === "Rejected") return window.open(`${window.location.origin}/rejected/${row.inspection_id}`, "_blank")
                }} src={nextBtn} alt=''/></div>
                </td>
            </tr>)}
            </tbody>
          </table>
        </div>
        {/* <div className={styles.Footer}> 
            <img src={logoFooter} alt='logo' /> 
            <p>{lang["Copyright"][language]}</p>
        </div> */}
        {showRemarkModal && <div className={styles.remarkModalContainer}>
          <div className={styles.remarkModal}>
          <h3>{lang["Add Remark"][language]}</h3>
            <textarea
              value = {remark}
              onChange={(e)=> setRemark(e.target.value)}
              className={styles.remarkInput}
            >
            </textarea>
            <div className={styles.remarkModalButtonContainer}>
              <div onClick={()=>sendRemarkData()}>{lang["Save"][language]}</div>
              <div onClick={()=>{
                setShowRemarkModal(false)
                remarkInspection = ""
                setRemark("")
              }}>{lang["Close"][language]}</div>
            </div>
          </div>
        </div>}
      </div>}
      {/* <div className={styles.ScrollImageContainer}>
        <img src={rightArrowAngle}></img>
        <p>Scroll</p>
        <p>Right</p>
      </div> */}
    </div>
  )
}

export default Claim